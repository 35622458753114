import * as Actions from "@/store/actions.type";
import * as Mutations from "@/store/mutations.type";
import { getAuth } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import * as Firebase from "@/common/firebase.js";
import {
  createServiceRequest,
  createServiceRequestPickUp,
  getAllServiceRequestPickUp,
  getAllServiceRequestDropOff,
  // createOpenFreightShipping,
  // resendEmailServiceRequestPickUp,
  serviceRequestPickUpQuote,
  createReturnConsignment
} from "@/common/salesAppApi";
import { getAllDealersUnprotected } from "@/common/salesAppApi";
import LOG_ACTIONS from "@/store/constants/log_actions.constants";
import MODULE from "@/store/constants/module.constants";
import getFirestoreCollectionConfig from "@/utils/FirestoreCollection";
const firestoreCollection = getFirestoreCollectionConfig();

export default {
  async [Actions.GET_ALL_DEALERS_UNPROTECTED]({ commit }) {
    commit(Mutations.SET_SERVICE_LOADING, true);
    try {
      const { data } = await getAllDealersUnprotected();
      commit(Mutations.SET_ALL_DEALERS, data.dealers);
    } catch (error) {
      commit(Mutations.SET_DEALER_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },

  [Actions.UPDATE_SELECTED_DEALER]({ commit }, dealer) {
    commit(Mutations.SET_SELECTED_DEALER, dealer);
  },

  async [Actions.CREATE_SERVICE_REQUEST]({ commit }, payload) {
    let data;
    try {
      data = await createServiceRequest(payload);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data;
  },

  async [Actions.CREATE_SERVICE_REQUEST_PICKUP]({ commit, dispatch }, payload) {
    let data = null;
    try {
      data = await createServiceRequestPickUp(payload);
      const currentUser = getAuth()?.currentUser;
      await dispatch(Actions.LOG_EVENT, {
        user: currentUser.email,
        action: LOG_ACTIONS.CREATE_SERVICE_REQUEST_PICKUP,
        module: MODULE.SERVICE_REQUEST,
        target: JSON.stringify({
          docId: data.data.doc.id,
          dealer: data.data.doc.basicInfo.name
        }),
        datePerformed: serverTimestamp()
      });
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data ? data : { data: { success: false } };
  },

  async [Actions.GET_ALL_SERVICEREQUESTS_PICKUP](
    { commit },
    { page = 1, filter, priorityId }
  ) {
    try {
      commit(Mutations.SET_SERVICE_LOADING, true);
      const {
        data: { serviceRequests, count }
      } = await getAllServiceRequestPickUp({ page, filter, priorityId });
      commit(Mutations.SET_ALL_SERVICEREQUESTS_PICKUP, serviceRequests);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT, count);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },

  async [Actions.GET_ALL_SERVICEREQUESTS_DROPOFF](
    { commit },
    { page = 1, filter }
  ) {
    try {
      commit(Mutations.SET_SERVICE_LOADING, true);
      const {
        data: { serviceRequests, count }
      } = await getAllServiceRequestDropOff({ page, filter });
      commit(Mutations.SET_ALL_SERVICEREQUESTS_DROPOFF, serviceRequests);
      commit(Mutations.SET_ALL_SERVICEREQUESTS_COUNT_DROPOFF, count);
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    commit(Mutations.SET_SERVICE_LOADING, false);
  },
  async [Actions.SERVICE_PICKUP_REBOOK_RESEND](
    {
      commit
      // dispatch
    },
    payload
  ) {
    let data = null;
    try {
      data = await createServiceRequestPickUp({ ...payload, isRebook: true });
      // const currentUser = getAuth()?.currentUser;
      // await dispatch(Actions.LOG_EVENT, {
      //   user: currentUser.email,
      //   action: LOG_ACTIONS.CREATE_SERVICE_REQUEST_PICKUP,
      //   module: MODULE.SERVICE_REQUEST,
      //   target: JSON.stringify({
      //     docId: data.data.doc.id,
      //     dealer: data.data.doc.basicInfo.name
      //   }),
      //   datePerformed: serverTimestamp()
      // });
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data ? data : { data: { success: false } };
  },
  async [Actions.UPDATE_SERVICE_REQUEST_PICKUP]({ commit }, payload) {
    let success = false;
    try {
      await setDoc(
        doc(Firebase.db, firestoreCollection.serviceRequestPickUp, payload.id),
        { ...payload.changes },
        { merge: true }
      );
      payload.emailType ? serviceRequestPickUpQuote(payload) : null;
      // const user = getAuth().currentUser;
      // await dispatch(Actions.LOG_EVENT, {
      //   user: user.email,
      //   action: LOG_ACTIONS.UPDATE_SERVICE_REQUEST_PICKUP,
      //   module: MODULE.SERVICE_REQUEST,
      //   target: JSON.stringify({ id: payload.id, caseId: payload.caseId }),
      //   changes: JSON.stringify(objectCompare(prevData, newData)),
      //   datePerformed: serverTimestamp()
      // });
      success = true;
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return success;
  },
  async [Actions.SERVICE_REQUEST_CREATE_RETURN_CONSIGNMENT](
    {
      commit
      // dispatch
    },
    payload
  ) {
    let data;
    try {
      data = await createReturnConsignment(payload);
      // const currentUser = getAuth()?.currentUser;
      // await dispatch(Actions.LOG_EVENT, {
      //   user: currentUser.email,
      //   action: LOG_ACTIONS.BOOK_OPEN_FREIGHT,
      //   module: MODULE.SERVICE_REQUEST,
      //   target: JSON.stringify({
      //     caseId: data.data.updatedRequest.caseId,
      //     consignment: data.data.updatedRequest.shipping.consignment,
      //     succeeded: data.data.succeeded
      //   }),
      //   datePerformed: serverTimestamp()
      // });
    } catch (error) {
      commit(Mutations.SET_SERVICEREQUEST_ERROR, error);
    }
    return data;
  }
};
